.btn {
  display: inline-block;
  padding: 10px 56px;

  font-family: $font-family600;
  font-size: 18px;
  font-weight: $medium-text;
  line-height: calc(22 / 18);

  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;

  @extend %transition;

  @media screen and (min-width: $desktop-width) {
    padding: 14px 32px;

    font-size: 24px;
    line-height: calc(28 / 24);
  }
}

.btn--primary {
  background-color: $accent-color;
  color: $black-color;

  &:hover,
  &:focus {
    background-color: $black-color;
    color: $white-color;
  }

  &:disabled {
    color: $background-color;
  }
}

.btn--secondary {
  background-color: $black-color;
  color: $white-color;

  &:hover,
  &:focus {
    background-color: $accent-color;
    color: $black-color;
  }

  &:disabled {
    color: $background-color;
  }
}
