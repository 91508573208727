@import './utils/reset';
@import './utils/visually-hidden';
@import './utils/variables';
@import './utils/mixins';
@import './utils/placeholders';
@import './utils/animations';

@import './common';

@import './components/container';
@import './components/section';
@import './components/button';
@import './components/modal';
@import './components/title';
@import './components//subtitle';
@import './components/quotes';

@import './layout/preLoader';
@import './layout/btnLoader';
@import './layout/authors';
@import './layout/contacts';
@import './layout/deliveryInfo';
@import './layout/description';
@import './layout/finalSumm';
@import './layout/hero';
@import './layout/footer';
@import './layout/howitworks';
@import './layout/modalSuccessOrder';
@import './layout/motivate';
@import './layout/order';
@import './layout/productOptions';
@import './layout/promocode';
@import './layout/qa';
@import './layout/personalCoatch';
@import './layout/modalFeedBack';
@import './layout/button-up';
@import './layout/socialButton';
@import './layout/swiperDetailedPlanner';
@import './layout/modalFeedBackSuccess';
@import './layout/video';

@import './layout/order-module-admin';
@import './layout/order-wrapper';
