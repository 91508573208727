.qa {
  padding-top: 72px;
  padding-bottom: 72px;
  @media screen and (min-width: $desktop-width) {
    padding-bottom: 172px;
    padding-top: 172px;
  }
  .qa__title {
    font-size: 28px;
    line-height: calc(32 / 28);
    font-family: $font-family700;
    text-align: center;

    @media screen and (min-width: $desktop-width) {
      font-size: 64px;
      line-height: calc(68 / 64);
    }
  }

  .qa__list {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 14px;

    @media screen and (min-width: $desktop-width) {
      margin-left: auto;
      margin-right: auto;
      max-width: 1032px;
      gap: 16px;
      margin-top: 72px;
    }
  }

  .qa__item {
    padding: 16px 14px;
    background-color: $background-color;
    cursor: pointer;

    @media screen and (min-width: $desktop-width) {
      padding-left: 32px;
      padding-right: 19px;
      padding-top: 20px;
    }
  }

  .qa-item__text {
    font-size: 14px;
    line-height: calc(20 / 14);
    font-family: $font-familyLight400;
    margin-top: 14px;
    color: $secondary-text-color;

    max-height: 800px;
    overflow-y: auto;
    scrollbar-width: none;
    transition: 0.8s;

    &.list-hidden {
      max-height: 0;
      overflow-y: hidden;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (min-width: $desktop-width) {
      font-size: 18px;
    }
  }

  .qa-item__title {
    font-family: $font-family500;
    font-weight: $medium-text;
    font-size: 18px;
    line-height: calc(24 / 18);

    @media screen and (min-width: $desktop-width) {
      font-size: 24px;
    }
  }

  .qa-btn__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .qa__btn {
    height: 24px;
    width: 24px;
    padding: 0;
    background: none;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(17, 17, 17, 0.12);
    cursor: pointer;

    transition-property: background-color, border, rotate;
    transition-duration: 250ms;
    transition-timing-function: $transition-timing-function;

    &:hover {
      border: 1px solid $accent-color;
      background-color: $accent-color;
    }

    &.opened {
      border: 1px solid $accent-color;
      background-color: $accent-color;
      rotate: 45deg;
    }

    @media screen and (min-width: $desktop-width) {
      height: 40px;
      width: 40px;
    }
  }

  .qa__icon {
    height: 24px;
    width: 24px;
    fill: none;
    stroke: black;

    @media screen and (min-width: $desktop-width) {
      height: 40px;
      width: 40px;
    }
  }
  .qa__item-link {
    color: $accent-color;
    font-family: $font-family500;

    transition-property: color, text-decoration;
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;

    &:hover {
      color: blue;
      text-decoration: underline;
    }
  }
}
