.container.orderProcessing__container {
  max-width: 375px;
  padding: 0 20px;
  @media screen and (min-width: $desktop-width) {
    max-width: 1440px;
  }
}

.header {
  margin-top: 20px;
}

.orderProcessing__logo {
  width: 97px;
  margin-bottom: 40px;
}

.orderProcessing__title {
  font-family: $font-family700;
  font-size: 24px;
  line-height: 1.17;
  letter-spacing: -0.02em;

  margin-bottom: 50px;

  @media screen and (min-width: $desktop-width) {
    font-size: 64px;
    line-height: 1.06;
  }
}

.orderProcessing__productWrapper {
  @media screen and (min-width: $desktop-width) {
    max-width: 756px;
  }
}

.section__title {
  font-family: $font-family400;
  font-size: 16px;
  line-height: 1.12;
  letter-spacing: -0.02em;

  margin-bottom: 24px;

  @media screen and (min-width: $desktop-width) {
    font-family: $font-family500;
    font-size: 24px;
    line-height: 1.33;
  }
}

.orderProcessing__plannerWrapper {
  display: flex;
}

.orderProcessing__planner {
  max-width: 73px;

  @media screen and (min-width: $desktop-width) {
    max-width: 187px;
  }
}

.orderProcessing__textPlannerWrapper {
  display: flex;
  width: 100%;
}

.orderProcessing__textWrapper {
  display: flex;
}

.orderProcessing__plannerDescr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 142px;
  margin-left: 16px;

  @media screen and (min-width: $desktop-width) {
    width: 347px;
    margin-left: 24px;
  }
}

.orderProcessing__titleWrapper {
  @media screen and (min-width: $desktop-width) {
    min-width: 200px;
  }
}

.orderProcessing__itemDescr {
  display: flex;
  flex-direction: column;

  width: 155px;
  margin-left: 16px;
  margin-right: 16px;

  @media screen and (min-width: $desktop-width) {
    flex-direction: row;
    width: 290px;
    height: 100px;
    margin-left: 24px;
  }
}

.orderProcessing__plannerTitle {
  font-family: $font-family400;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: -0.02em;

  @media screen and (min-width: $desktop-width) {
    font-family: $font-family500;
    font-size: 24px;
    line-height: 1.33;
  }
}

.orderProcessing__plannerParagraph {
  font-family: $font-family400;
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: -0.02em;

  color: $secondary-text-color;

  @media screen and (min-width: $desktop-width) {
    font-size: 18px;
    line-height: 1.33;
  }
}

.orderProcessing__priceDescr {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.orderProcessing__price {
  letter-spacing: -0.02em;
  color: $secondary-text-color;
  margin-bottom: 8px;
}

.orderProcessing__priceCancelled {
  font-family: $font-family400;
  font-size: 8px;
  line-height: 1.1;

  letter-spacing: -0.01em;
  text-decoration-line: line-through;

  color: $black-color;

  @media screen and (min-width: $desktop-width) {
    font-size: 14px;
    line-height: 1.29;
  }
}

.orderProcessing__priceCurrent {
  font-family: $font-family600;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: -0.01em;
  color: $alert-color;

  @media screen and (min-width: $desktop-width) {
    font-size: 24px;
    line-height: 1.33;
  }
}

.orderProcessing__amount {
  max-width: 335px;
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 28px;

  @media screen and (min-width: $desktop-width) {
    max-width: 545px;
  }
}

.orderProcessing__ItemImgWrapper {
  position: relative;
  min-width: 52px;
  min-height: 68px;
  overflow: hidden;

  @media screen and (min-width: $desktop-width) {
    min-width: 74px;
    min-height: 96px;
  }
}

.orderProcessing__ItemImg {
  position: absolute;
  top: 18px;
  scale: 2.8;

  z-index: -2;
  max-width: 100%;
  @media screen and (min-width: $desktop-width) {
    top: 26px;
  }
}

.orderProcessing__list {
  display: flex;
  flex-direction: column;
}

.orderProcessing__item {
  min-width: 335px;
}

.orderProcessing__item:not(:last-child) {
  margin-bottom: 20px;

  @media screen and (min-width: $desktop-width) {
    margin-bottom: 0px;
  }
}

.orderProcessing__itemWrapper {
  display: flex;
  height: 95px;

  @media screen and (min-width: $desktop-width) {
    align-items: center;
    height: 140px;
  }
}

.orderProcessing__item:after {
  content: '';
  display: block;
  width: 335px;
  height: 1px;
  background: $black-color;
  opacity: 0.1;

  @media screen and (min-width: $desktop-width) {
    width: 545px;
  }
}

.orderProcessing__itemTitle {
  font-family: $font-familyLight400;
  font-size: 12px;
  line-height: 1;
  letter-spacing: -0.02em;

  margin-bottom: 4px;

  color: $black-color;

  @media screen and (min-width: $desktop-width) {
    font-size: 14px;
    line-height: calc(18 / 14);
    max-width: 180px;
  }
}

.orderProcessing__itemParagraph {
  font-family: $font-family400;
  font-size: 8px;
  line-height: 1.5;
  letter-spacing: -0.02em;
  color: $secondary-text-color;
  margin-bottom: 14px;

  @media screen and (min-width: $desktop-width) {
    margin-bottom: 0px;

    font-size: 12px;
    line-height: 1.5;
  }
}

.orderProcessing__inputWrapper {
  display: flex;
  align-items: center;

  @media screen and (min-width: $desktop-width) {
    width: 77px;
    align-items: baseline;
  }
}

.orderProcessing__minus {
  cursor: pointer;
  width: 12px;
  height: 12px;
}

.orderProcessing__plus {
  @extend .orderProcessing__minus;
}

.orderProcessing__close {
  cursor: pointer;
  width: 14px;
  height: 14px;
  display: flex;
}

.orderProcessing__number {
  height: 16px;
  min-width: 37px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0 4px;
  border: 0.5px solid rgba(17, 17, 17, 0.2);
  border-radius: 2px;

  font-family: $font-family400;
  font-size: 12px;
  line-height: 1;

  color: $black-color;

  @media screen and (min-width: $desktop-width) {
    width: 41px;
    height: 20px;

    border: 0.8px solid rgba(17, 17, 17, 0.2);

    font-size: 14px;
  }
}

.orderProcessing__costBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  font-family: $font-family600;
  font-size: 12px;
  line-height: 1;

  color: $black-color;

  font-family: MacPawFixelDisplay500, sans-serif;
  font-size: 12px;
  line-height: 1.5;

  @media screen and (min-width: $desktop-width) {
    font-family: MacPawFixelDisplay500, sans-serif;
    font-size: 16px;
    line-height: calc(18 / 16);

    min-width: 130px;
  }
}

.orderProcessing__addBtn {
  font-family: $font-family400;
  font-size: 12px;
  line-height: 1;
  color: #ffffff;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  max-height: 30px;
  background-color: black;
  border-radius: 4px;
  max-width: 55px;

  @media screen and (min-width: $desktop-width) {
    margin-left: 17px;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: -0.01em;
    max-width: none;
    padding: 10px;
  }
}
.orderProcessing__addBtn:hover{
background-color: #f3bb4a;
color: black;
}
/* .orderProcessing__addBtn::after {
  content: '';
  display: block;
  width: 43px;
  height: 1px;
  background: $secondary-text-color;
  opacity: 0.5;

  @media screen and (min-width: $desktop-width) {
    width: 49px;
  }
} */

.orderProcessing__lastItemsLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 8px;
  font-weight: $bold-text;
  color: $secondary-text-color;
  height: 18px;
  background-color: $accent-color;
  border-radius: 10px;
  width: 70px;
  @media screen and (min-width: $desktop-width) {
    width: 80px;
    font-size: 10px;
  }
}

.orderProcessing__firstCostWrapper {
  display: flex;
  font-size: 14px;
  font-weight: $bold-text;

  @media screen and (min-width: $desktop-width) {
    font-size: 16px;
  }
}

.orderProcessing__firstCostTitle {
  width: 148px;
  margin-left: 68px;
  margin-right: 16px;
  @media screen and (min-width: $desktop-width) {
    margin-left: 98px;
    width: 290px;
  }
}

.orderProcessing__firstCostValue {
  width: 130px;
  text-align: center;
}

.orderProcessing__noPlannersWarning {
  font-family: $font-familyLight400;
  font-size: 12px;
  line-height: 1;
  letter-spacing: -0.02em;

  margin-bottom: 4px;

  color: $black-color;

  @media screen and (min-width: $desktop-width) {
    font-size: 14px;
    line-height: 1.29;
    max-width: 375px;
  }
}

.own-visually-hidden {
  position: absolute;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.orderProcessing__addText {
 
  font-family: $font-family500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: -0.02em;
  margin-bottom: 4px;

  color: $black-color;

  @media screen and (min-width: $desktop-width) {
    font-size: 14px;
    line-height: calc(18 / 14);
  }
}
.orderProcessing__additional{
  max-width: 756px;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}
.orderProcessing__link {
  color: $accent-color;
    font-family: $font-family500;

    transition-property: color, text-decoration;
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;

    &:hover {
      color: blue;
      text-decoration: underline;
    }
}