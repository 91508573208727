.swiper-detailed {
  width: 100%;
  padding-bottom: 50px;

  @media screen and (min-width: $desktop-width) {
    padding-bottom: 72px;
  }

  .swiper-detailed__swiper {
    margin: 0;
    overflow: hidden;
    /* width: 550px; */
  }

  .swiper-slide > picture {
    object-fit: fill;

    @media screen and (min-width: $tablet-width) {
      position: static;
      width: auto;
    }

    @media screen and (min-width: $desktop-width) {
      width: 1240px;
      position: static;
    }
  }

  .swiper-slide {
    /* width: 600px; */
    position: relative;
    left: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    &:nth-child(3) {
      left: -70px;
    }
    &:nth-child(4) {
      left: -70px;
    }
    &:nth-child(6) {
      left: -70px;
    }

    @media screen and (min-width: $tablet-width) {
      position: static;
      margin-right: 0;
    }

    @media screen and (min-width: $desktop-width) {
      position: static;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0 auto;
      /*       max-height: 100%; */
    }
  }

  .swiper-slide__img {
    margin: 0;
    width: 503px;
    height: 447px;
    // max-width: auto;

    @media screen and (min-width: $tablet-width) {
      width: auto;
      height: 600px;
    }

    @media screen and (min-width: $desktop-width) {
      margin: 0 auto;
      width: auto;
      height: 80vh;
    }
  }

  .swiper-wrapper {
    height: 600px;
    @media screen and (min-width: $tablet-width) {
      height: auto;
    }
    @media screen and (min-width: $desktop-width) {
      height: auto;
    }
  }

  .swiper-detailed__pagination {
    justify-content: center;
    gap: 10px;
    display: flex;
    position: relative;
    margin-top: 32px;
    width: 100%;
    left: 0;
    z-index: 50;

    @media screen and (min-width: 1440px) {
      margin-top: 40px;
    }
    span {
      width: 14px;
      height: 14px;
      border: 2px solid $background-color;
      border-radius: 50%;
      background-color: $black-color;
    }
    @media screen and (min-width: 1440px) {
      span {
        width: 20px;
        height: 20px;
      }
    }
  }
  .swiper-pagination-bullet {
    opacity: 1;

    transition-property: scale, border, background-color;
    transition-duration: 300ms;
    transition-timing-function: $transition-timing-function;

    &:hover {
      scale: 1.2;
    }
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    border: 2px solid $accent-color;
    scale: 1.2;
    background-color: $accent-color;
  }
}
