.container.contacts__container {
  max-width: 375px;
  padding: 72px 20px 36px;
  @media screen and (min-width: $desktop-width) {
    max-width: 1440px;
  }
}

.contacts__container {
  padding-top: 72px;
  padding-bottom: 36px;
  @media screen and (min-width: $desktop-width) {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
.contacts__form {
  max-width: 335px;
  @media screen and (min-width: $desktop-width) {
    max-width: 420px;
  }
}

.contacts__label {
  display: block;
  font-family: $font-family400;
  font-size: 10px;
  line-height: calc(14 / 10);
  letter-spacing: -0.01em;
  margin-bottom: 4px;
  color: $secondary-text-color;
  @media screen and (min-width: $desktop-width) {
    font-size: 16px;
    line-height: calc(18 / 16);
  }
}
.contacts__input {
  width: 100%;
  padding: 12px;
  background: $background-color;
  border-radius: 4px;
  font-size: 12px;
  line-height: calc(14 / 12);
  letter-spacing: -0.01em;
  color: #3c3b3b;
  border: none;
  outline: transparent;
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 0;
  }
  &::placeholder {
    font-family: $font-family400;
    color: rgba(60, 59, 59, 0.3);
  }
  &--textarea {
    min-height: 95px;
    resize: none;
    margin-bottom: 18px;

  }

  @media screen and (min-width: $desktop-width) {
    font-size: 18px;
    line-height: calc(24 / 18);
    padding: 16px 14px;
    margin-bottom: 24px;
    &--textarea {
      min-height: 132px;
      margin-bottom: 40px;
    }
  }
}
.contacts__btn {
  margin-top: 14px;
  padding: 10px 44px;
  &:disabled {
    background: grey;
  }
  @media screen and (min-width: $desktop-width){
    margin-top: 24px;
  }
}

.iti {
  width: 100%;
  letter-spacing: -0.01em;
  color: #3c3b3b;
  background: #f3f3f3;
  border: none;
  border-radius: 4px;
  outline: #0000;
  margin-bottom: 14px;
  font-size: 12px;
  line-height: 1.16667;
  @media screen and (min-width: $desktop-width) {
    font-size: 18px;
    line-height: 1.33333;
  }
}

.iti__country-list {
  width: 335px;
  @media screen and (min-width: $desktop-width) {
    width: 420px;
  }
}

.contacts__input.contacts__phone {
  padding-left: 84px !important;
  @media screen and (min-width: $desktop-width) {
    padding-left: 100px !important;

  }
}
.contacts__name.invalid {
  border: 1px solid red;
  outline: none;
}

.contacts__input.invalid {
  border: 1px solid red;
  outline: none;
}
.contacts__phone.invalid {
  border: 1px solid red;
  outline: none;
}