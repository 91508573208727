@import '../utils/mixins';

.container.delivery__container {
  max-width: 375px;
  padding: 0px 20px 36px;
  @media screen and (min-width: $desktop-width) {
    max-width: 1440px;
  }
}

.delivery__container {
  // padding-top: 36px;
  // padding-bottom: 36px;
  max-height: 100%;
  transition-property: opacity;
  transition-duration: 500ms;
  transition-timing-function: $transition-timing-function;

  // @media screen and (min-width: $desktop-width) {
  //   padding-top: 50px;
  //   padding-bottom: 200px;
  // }
  &.delivery__ishidden {
    @include visually-hidden;
  }
}
.city-wrapper {
  max-width: 335px;
  position: relative;
  margin-bottom: 14px;

  @media screen and (min-width: $desktop-width) {
    max-width: 420px;
    margin-bottom: 24px;
  }
}

.input-label {
  display: block;
  font-size: 10px;
  line-height: calc(14 / 10);
  margin-bottom: 4px;
  color: #3c3b3b;

  @media screen and (min-width: $desktop-width) {
    font-size: 16px;
    line-height: calc(18 / 16);
  }
}

.input {
  display: block;
  font-size: 12px;
  line-height: calc(14 / 12);
  padding: 14px 12px;
  width: 100%;
  border-radius: 4px;
  outline: inherit;

  @media screen and (min-width: $desktop-width) {
    font-size: 18px;
    line-height: calc(24 / 18);
    padding: 16px 14px;
  }
}

.input--city {
  border: none;
  background-color: $background-color;

  &::placeholder {
    color: rgba(60, 59, 59, 0.3);
  }
}

.warehouse-wrapper {
  max-width: 335px;
  position: relative;

  @media screen and (min-width: $desktop-width) {
    max-width: 420px;
    margin-bottom: 40px;
  }
}

.warehouse-btn {
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: calc(14 / 12);
  padding: 14px 12px;
  border-radius: 4px;
  text-align: left;
  border: 1px solid rgba(60, 59, 59, 0.1);
  color: $black-color;
  background-color: $white-color;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    display: block;
    width: 18px;
    height: 18px;
    background-image: url('../icons/arrow_down.svg');
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: center center;

    transition: transform $transition-duration $transition-timing-function;
  }

  &.active {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  @media screen and (min-width: $desktop-width) {
    font-size: 18px;
    line-height: calc(24 / 18);
    padding: 16px 14px;

    &::after {
      right: 15px;
      width: 24px;
      height: 24px;
      background-size: 24px 24px;
    }
  }
}

.warehouse-search {
  display: none;
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;

  @media screen and (min-width: $desktop-width) {
    top: 90px;
  }
}

.input--warehouse {
  border: 1px solid rgba(60, 59, 59, 0.1);
  border-radius: 4px 4px 0 0;
  margin-bottom: 0;
}

.autocomplete-list {
  display: none;
  flex-direction: column;
  gap: 12px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: $white-color;
  z-index: 10;
  color: $black-color;
  font-size: 12px;
  line-height: calc(18 / 12);
  padding: 12px;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid rgba(60, 59, 59, 0.1);

  li {
    cursor: pointer;
  }

  @media screen and (min-width: $desktop-width) {
    font-size: 16px;
    line-height: calc(20 / 16);
    gap: 14px;
  }
}

.warehouses {
  border-top: unset;
  max-height: 200px;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: $background-color;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $accent-color;
  }
}

.autocomplete-list.show {
  display: flex;
}

.warehouse-search.show {
  display: block;
  margin-bottom: 40px;
}

.contacts__label--checkbox {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}
