.subtitle {
  font-size: 16px;
  font-family: $font-family500;
  line-height: calc(18 / 16);
  margin-bottom: 18px;

  @media screen and (min-width: $desktop-width) {
    font-size: 24px;
    line-height: calc(32 / 24);
    margin-bottom: 40px;
  }
}
