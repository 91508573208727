.container-order {
  @media screen and (min-width: $desktop-width) {
    max-width: 822px;
    padding: 0;
  }
}

.order {
  font-size: 14px;
  font-family: $font-family400;
  text-align: center;
  letter-spacing: -0.02em;
  color: $black-color;
}

.pre-order-title {
  margin-bottom: 16px;
  font-size: 28px;
  line-height: calc(32 / 28);

  @media screen and (min-width: $desktop-width) {
    font-size: 32px;
    line-height: calc(38 / 32);
    margin-bottom: 18px;
  }

  &__crossed {
    text-decoration: line-through;
  }
}

.order-title {
  margin-bottom: 8px;
  @media screen and (min-width: $desktop-width) {
    font-size: 32px;
    margin-bottom: 18px;
  }

  &__accent {
    color: $accent-color;
  }
}

.pre-order,
.order-now {
  line-height: calc(22 / 18);

  &__text {
    font-size: 18px;
    font-family: $font-family500;
    margin-bottom: 16px;

    @media screen and (min-width: $desktop-width) {
      font-size: 24px;
      line-height: calc(28 / 24);
      margin-bottom: 14px;
    }
  }

  &__price {
    color: $alert-color;
  }

  &__delivery {
    display: inline-block;
    margin-bottom: 24px;
    color: $secondary-text-color;
    font-family: $font-family400;

    @media screen and (min-width: $desktop-width) {
      margin-bottom: 32px;
      font-size: 18px;
      font-family: $font-family400;
      line-height: calc(24 / 18);
    }
  }

  &__btn {
    display: block;
    margin: 0 auto;
    padding: 12px 62px 8px;
    margin-bottom: 24px;
    font-family: $font-family600;
    letter-spacing: -0.02em;
    font-size: 18px;
    line-height: calc(22 / 18);

    max-width: 272px;
    margin-bottom: 14px;
    padding: 15px 31px 13px;

    @media screen and (min-width: $desktop-width) {
      margin-bottom: 14px;
      padding: 15px 31px 13px;
      width: 272px;
    }
  }

  &__btn--feedback {
    padding: 0;
    border: none;
    font-family: $font-family500;
    margin-bottom: 32px;
    text-decoration: underline;
    font-size: 14px;
    background: none;
    line-height: calc(18 / 14);

    transition-property: color;
    transition-duration: 250ms;
    transition-timing-function: $transition-timing-function;

    @media screen and (min-width: $desktop-width) {
      margin-bottom: 50px;
      font-size: 18px;
      line-height: calc(28 / 18);
    }

    &:hover {
      cursor: pointer;
      color: $accent-color;
    }
  }
}

.pre-order__delivery {
  width: 246px;
  @media screen and (min-width: $desktop-width) {
    width: 350px;
  }
}

.motivation-block {
  padding: 14px;
  // max-width: 824px;
  margin: 0 auto;
  background-color: $background-color;
  text-align: left;
  font-family: $font-family400;
  line-height: calc(22 / 14);

  @media screen and (min-width: $tablet-width) {
    padding: 46px 50px 42px;
  }
  @media screen and (min-width: $desktop-width) {
    padding: 62px 66px;
    max-width: 824px;
  }

  &__link {
    font-family: $font-family700;
    font-style: normal;
  }

  &__text {
    font-size: 14px;
    @media screen and (min-width: $tablet-width) {
      font-size: 24px;
      line-height: 1.33333;
    }

    @media screen and (min-width: $desktop-width) {
      font-size: 24px;
    }
  }

  &__text br {
    display: none;
    @media screen and (min-width: $desktop-width) {
      display: initial;
      padding-top: 20px;
    }
  }
}
