@import '../utils/mixins';

.container.promo__container {
  max-width: 375px;
  padding: 36px 20px 36px;
  @media screen and (min-width: $desktop-width) {
    max-width: 1440px;
    padding: 0px 20px 36px;
  }
}

.promo__container {
  padding-top: 36px;
  padding-bottom: 28px;
  @media screen and (min-width: 1440px) {
    padding: 0 0 28px 0;
  }

  &.promo__ishidden {
    @include visually-hidden;
  }
}

.promo__title {
  @media screen and (min-width: $desktop-width) {
    display: none;
  }
}
.complite-form {
  background: $background-color;
  border-radius: 4px;
  max-width: 335px;

  @media screen and (min-width: $desktop-width) {
    max-width: 420px;
  }
}
.promo__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  // width: 335px;
  height: 46px;
  background: $background-color;
  border-radius: 4px;
  @media screen and (min-width: 1440px) {
    width: 420px;
    height: 56px;
    padding-left: 14px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
.promo__label {
  font-family: $font-family500;
  font-size: 14px;
  line-height: 129%;
  letter-spacing: -0.01em;
  color: $black-color;
  @media screen and (min-width: 1440px) {
    font-size: 18px;
    line-height: 133%;
  }
}
.promo__toggle {
  font-family: $font-family400;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: $secondary-text-color;
  @media screen and (min-width: 1440px) {
    font-size: 16px;
    line-height: 112%;
  }
}
.promo__hide {
  margin-top: 14px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 14px;
  @media screen and (min-width: 1440px) {
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 18px;
  }
}
.promo__error {
  position: relative;
}
.promo__input {
  width: 100%;
  background: $white-color;
  border: 1px solid rgba(60, 59, 59, 0.1);
  border-radius: 4px;
  padding: 14px 12px;
  font-size: 12px;
  line-height: 117%;
  display: block;
  color: $black-color;
  font-family: $font-family400;
  @media screen and (min-width: 1440px) {
    padding: 14px;
    font-size: 18px;
    line-height: 133%;
  }
}
.promocode__icon {
  position: absolute;
  top: 17%;
  left: 282px;
  @media screen and (min-width: 1440px) {
    left: 356px;
  }
}
.promo__submit {
  display: inline-flex;
  margin-top: 8px;
  justify-content: center;
  align-items: center;
  width: 311px;
  height: 38px;
  background-color: $accent-color;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-family: $font-family500;
  font-size: 16px;
  line-height: 112%;
  letter-spacing: -0.02em;
  color: $black-color;
  @media screen and (min-width: 1440px) {
    width: 392px;
    height: 56px;
    font-size: 24px;
    line-height: 117%;
  }
}
.promo__success {
  margin-top: 12px;
  text-align: left;
  @media screen and (min-width: 1440px) {
    margin-top: 14px;
  }
}
.promo__success-text {
  font-family: $font-family400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: $success-color;
  margin-bottom: 8px;
  @media screen and (min-width: 1440px) {
    font-size: 14px;
    line-height: 129%;
  }
}
.promo__price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.promo__final {
  font-family: $font-family400;
  font-size: 14px;
  line-height: 129%;
  letter-spacing: -0.01em;
  color: $black-color;
  @media screen and (min-width: 1440px) {
    font-size: 18px;
    line-height: 133%;
  }
}
.promo__discount {
  font-family: $font-family600;
  font-size: 14px;
  line-height: 129%;
  letter-spacing: -0.01em;
  color: $black-color;
  @media screen and (min-width: 1440px) {
    font-size: 18px;
    line-height: 133%;
  }
}
