.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: rgba($color: $black-color, $alpha: 0.6);
  transition: opacity $transition-duration $transition-timing-function,
    visibility $transition-duration $transition-timing-function;
  overflow-y: scroll;
  z-index: 30;

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
}

.modal {
  position: relative;
  padding: 28px;
  background-color: $white-color;
  transition: transform $transition-duration $transition-timing-function;

  .backdrop.is-hidden & {
    transform: scale(1.1);
  }
}

.close-btn {
  padding: 0;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 14px;
  right: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media screen and (min-width: $desktop-width) {
    width: 24px;
    height: 24px;
    top: 18px;
    right: 18px;
  }
}

.close-btn__icon {
  width: 14px;
  height: 14px;

  @media screen and (min-width: $desktop-width) {
    width: 18px;
    height: 18px;
  }
}
