.modalFeedBack {
  transition: opacity 300ms $transition-timing-function;

  .modalFeedBack__modal {
    min-width: 327px;
    max-width: 100%;
    background-color: $white-color;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    padding: 0 14px;
    padding-top: 153px;
    padding-bottom: 32px;

    @media screen and (min-width: $desktop-width) {
      min-width: 896px;
      max-height: 726px;
      padding-top: 96px;
      padding-right: 72px;
      padding-bottom: 72px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  .modalFeedBack__img {
    width: 168px;
    position: absolute;
    left: -56px;
    top: -61px;

    @media screen and (min-width: $desktop-width) {
      width: 401px;
      left: -95px;
      top: -81px;
    }
  }

  .modalFeedBack__icon {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 18px;
    top: 18px;

    &:hover {
      cursor: pointer;
    }

    @media screen and (min-width: $desktop-width) {
      width: 24px;
      height: 24px;
      right: 30px;
      top: 30px;
    }
  }

  .modalFeedBack__title {
    text-align: center;
    font-size: 18px;
    font-family: $font-family700;
    line-height: calc(22 / 18);
    letter-spacing: -0.02em;

    @media screen and (min-width: $desktop-width) {
      font-size: 32px;
      line-height: calc(38 / 32);
      letter-spacing: -0.02em;
    }
  }

  .modalFeedBack__form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 26px;
    max-width: 420px;

    @media screen and (min-width: $desktop-width) {
      margin-top: 54px;
      gap: 18px;
    }
  }

  .modalFeedBack__label {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 10px;
    font-family: $font-family400;
    color: $secondary-text-color;

    @media screen and (min-width: $desktop-width) {
      font-size: 16px;
      line-height: calc(18 / 16);
    }
  }

  .modalFeedBack__input {
    width: 100%;
    padding: 12px 0;
    padding-left: 12px;
    background-color: $background-color;
    outline: none;
    border: 1px solid $background-color;
    border-radius: 4px;
    font-size: 12px;
    line-height: calc(14 / 12);
    font-family: $font-family400;

    @media screen and (min-width: $desktop-width) {
      padding: 16px 0;
      padding-left: 14px;
      font-size: 18px;
      line-height: calc(24 / 18);
    }

    &::placeholder {
      font-family: $font-family400;
      color: rgba(60, 59, 59, 0.3);
    }

    @media screen and (min-width: $desktop-width) {
      width: 420px;
    }
  }

  .modalFeedBack__input--comment {
    min-height: 88px;
    resize: none;
    margin-bottom: 6px;

    @media screen and (min-width: $desktop-width) {
      min-height: 132px;
      margin-bottom: 14px;
    }
  }

  .modalFeedBack__btn {
    font-size: 16px;
    font-family: $font-family600;
    line-height: calc(18 / 16);
    letter-spacing: -0.02em;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
    &:disabled {
      background-color: transparent;
      color: grey;
      border: 1px solid #f3bb4a;
    }
    @media screen and (min-width: $desktop-width) {
      font-size: 24px;
      line-height: calc(28 / 24);
    }
  }

  .modalFeedBack__title--wrapper {
    text-align: center;

    @media screen and (min-width: $desktop-width) {
      width: 420px;
    }
  }
}

.modal-is-hidden {
  opacity: 0;
  pointer-events: none;
}

.modalFeedBack__form .iti {
  margin-bottom: 0px;
}
.modalFeedBack__input.invalid {
border: 1px solid red;
}