.motivate__wrapper {
  @extend %centered;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 72px;
  @media screen and (min-width: $tablet-width) {
    gap: 42px;
  }
  @media screen and (min-width: $desktop-width) {
    max-width: 1032px;
    gap: 72px;
    margin-bottom: 172px;
  }
}

.motivate__blocks {
  display: flex;
  flex-direction: column;
  gap: 14px;

  @media screen and (min-width: $desktop-width) {
    flex-direction: row;
    gap: 210px;
  }
}

.motivate__list {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;

  @media screen and (min-width: $tablet-width) {
    grid-template-rows: repeat(4, auto);
    grid-auto-flow: column;
    // grid-auto-columns: 421px;
    grid-auto-columns: 44%;
    column-gap: 20px;
    row-gap: 28px;
  }
  @media screen and (min-width: $desktop-width) {
    column-gap: 175px;
    max-width: 1032px;
    grid-auto-columns: 38%;
    row-gap: 38px;
  }
}

.motivate__item {
  display: flex;
  // align-items: flex-start;
  align-items: center;

  @media screen and (min-width: $desktop-width) {
    align-items: stretch;
  }
}

.icon-motivate {
  margin-right: 20px;
  max-width: 24px;
  height: 24px;

  @media screen and (min-width: $desktop-width) {
    margin-right: 18px;
    min-width: 32px;
    min-height: 32px;
  }
}

.motivate__paragraph {
  font-family: $font-family500;
  font-size: 14px;
  line-height: calc(18 / 14);
  font-weight: $medium-text;
  letter-spacing: -0.02em;

  @media screen and (min-width: $tablet-width) {
    font-size: 18px;
    line-height: 1.22222;
  }

  @media screen and (min-width: $desktop-width) {
    font-size: 24px;
    line-height: calc(32 / 24);
    font-weight: $medium-text;
    letter-spacing: -0.02em;
  }
}

.strong {
  font-family: $font-family700;
}

.motivate .title {
  @extend %centered;
  margin-bottom: 0px;
  width: 230px;
  font-family: $font-family700;
  font-style: normal;
  font-weight: $bold-text;
  font-size: 28px;
  line-height: 1.14;
  letter-spacing: -0.02em;

  @media screen and (min-width: $tablet-width) {
    width: 450px;
    font-size: 44px;
    line-height: 1.22;
  }

  @media screen and (min-width: $desktop-width) {
    width: 554px;
    font-size: 64px;
    line-height: 1;
    font-weight: $bold-text;
  }
}
