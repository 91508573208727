.personal-coatch {
  width: 100%;
  @media screen and (min-width: $desktop-width) {
    width: 100%;
  }
}

.personal-coatch__wrapper {
  margin: 0 auto;
  margin-bottom: 72px;
  // max-width: 335px;
  @media screen and (min-width: $desktop-width) {
    max-width: 1032px;
    margin-bottom: 172px;
  }
}

.personal-coatch__title {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  &--accent {
    color: $accent-color;
  }
  @media screen and (min-width: $tablet-width) {
    max-width: 500px;

    // margin-bottom: 30px;
  }
  @media screen and (min-width: $desktop-width) {
    max-width: 724px;
  }
}

.personal-coatch__list {
  width: 90vw;
  margin: 0 auto;
  @media screen and (min-width: $tablet-width) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px 20px;
    height: 224px;
    width: 90vw;
    margin: 0 auto;
  }
  @media screen and (min-width: $desktop-width) {
    width: 1032px;
    gap: 24px 20px;
    height: 300px;
  }
}

.personal-coatch__item {
  margin-bottom: 8px;
  padding: 8px 18px;
  display: flex;
  align-items: center;
  gap: 18px;

  margin: 0 auto;
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (min-width: 500px) {
    width: 70%;
  }
  @media screen and (min-width: $tablet-width) {
    width: auto;
    margin: 0;
  }
  @media screen and (min-width: $desktop-width) {
    margin-bottom: 0;
    gap: 32px;
    padding: 16px 32px;
    width: 506px;
  }
}

.personal-coatch__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border: 1px solid $secondary-text-color;
  border-radius: 50%;
  @media screen and (min-width: $desktop-width) {
    width: 52px;
    height: 52px;
  }
}

.personal-coatch__icon {
  width: 24px;
  height: 24px;
  @media screen and (min-width: $desktop-width) {
    width: 32px;
    height: 32px;
  }
}

.personal-coatch__descr {
  font-family: $font-family700;
  font-size: 18px;
  line-height: calc(22 / 18);
  letter-spacing: -0.02em;
  color: $black-color;
  @media screen and (min-width: $desktop-width) {
    font-size: 24px;
    line-height: calc(32 / 24);
  }
}
