.video_section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.video_mob,
.video_web {
  max-height: 80vh;
  max-width: 80vw;
}
.video_icon {
  height: 35px;
}
.video_icons_wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 30px;
  max-width: 85vw;
  @media screen and (min-width: $tablet-width) {
    flex-direction: row;
    gap: 50px;
  }
}
.video_text_wrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}
.video__text {
  font-size: 14px;
  line-height: calc(20 / 14);
  font-family: $font-familyLight400;
  color: $secondary-text-color;
  @media screen and (min-width: $desktop-width) {
    font-size: 18px;
  }
}
