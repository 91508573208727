footer {
  display: flex;
  justify-content: center;
  background-color: $black-color;
}

.footer__section {
  max-width: 90vw;
  padding: 0 20px;
  width: 100%;
  position: relative;
  padding: 40px 20px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
  }
  @media screen and (min-width: $desktop-width) {
    padding: 50px 100px 100px;
    z-index: 0;
  }
}
.footer__contacts {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 60px;
  @media screen and (min-width: $desktop-width) {
    gap: 14px;
    margin-bottom: 0;
  }
}
.footer__title {
  font-family: $font-family700;
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: -0.02em;
  color: $accent-color;
  @media screen and (min-width: $desktop-width) {
    font-size: 24px;
    line-height: 1.33;
  }
}
.footer__text {
  font-family: $font-familyExtraLight400;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: $white-color;
  @media screen and (min-width: $desktop-width) {
    font-size: 18px;
    line-height: 1.33;
  }
}
.footer__icons {
  display: flex;
  flex-direction: row;
  gap: 18px;

  @media screen and (min-width: 768px) {
    order: 1;
  }
}

.footer__icon {
  fill: $white-color;
  width: 28px;
  height: 28px;

  @media screen and (min-width: $desktop-width) {
    width: 32px;
    height: 32px;
  }
}

.footer-btn-wrapper {
  width: 100%;
  @media screen and (min-width: 768px) {
    width: auto;
    margin-right: 30px;
  }
}

.footer__button[disabled] {
  pointer-events: none;
}

.footer__button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 268px;
  height: 60px;
  margin: 0 auto;

  padding: 10px 15px;
  font-family: $font-family600;
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: -0.02em;
  border: 1px solid transparent;
}
.footer__button:hover,
.footer__button:focus {
  border: 1px solid $accent-color;
}
