.hero-wrapper {
  min-height: 100vh;
}
// .hero {
// //   padding-top: 40px;
//   @media screen and (min-width: 1440px) {
//     // padding-top: 70px;
//     // height: 100vh;
//   }
// }
// .hero {
//   min-height: 100vh;
//   display: grid;
//   place-items: center;
//   @media screen and (min-width: 1440px) {
//     // padding-top: 70px;
//   }
// }
.hero-container {
  padding: 0;
  @media screen and (min-width: 1440px) {
    padding: 0 100px;
    max-width: 1600px;
  }
}
.hero__main {
  @media screen and (min-width: $tablet-width) {
    display: flex;
    align-items: flex-start;
    max-height: 507px;
    max-width: 1920px;
    padding: auto;
    gap: 10px;
    justify-content: space-between;
  }
}
.hero__title {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
  @media screen and (min-width: 751px) {
    flex-direction: column-reverse;
    gap: 94px;
    padding: 0;
    width: 40vw;
    align-items: self-start;
  }
  @media screen and (min-width: 1400px) {
    width: auto;
  }
}

.logo-link {
  margin-bottom: 14px;
  display: block;
  width: 265px;
  height: 82px;
  @media screen and (min-width: 1440px) {
    width: 625px;
    height: 177px;
  }
}
.hero__logo {
  display: inline-block;
  width: 265px;
  height: 82px;
  @media screen and (min-width: 751px) {
    width: 35vw;
    height: auto;
  }
  @media screen and (min-width: 1440px) {
    width: 625px;
    height: 177px;
  }
}
.hero__description {
  font-family: $font-family700;
  font-weight: $bold-text;
  width: 295px;
  font-size: 18px;
  line-height: 122%;
  letter-spacing: -0.02em;
  color: $black-color;
  margin-bottom: 14px;
  @media screen and (min-width: $tablet-width) {
    font-size: 28px;
    line-height: 119%;
    width: 38vw;
  }
  @media screen and (min-width: $desktop-width) {
    width: 420px;
    font-size: 32px;
    line-height: 119%;
  }
}

.hero__accent-text {
  color: $accent-color;
}

.hero__slider-text {
  width: 162px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 32px;
  font-family: $font-family400;
  font-weight: $regular-text;
  font-size: 14px;
  line-height: 129%;
  text-align: center;
  letter-spacing: -0.02em;
  color: $black-color;

  @media screen and (min-width: $tablet-width) {
    margin-top: -50px;
    margin-bottom: 18px;
    width: 227px;
    margin-left: 0;
    font-size: 18px;
    line-height: 120%;
    text-align: left;
  }

  @media screen and (min-width: $desktop-width) {
    margin-top: 0;

    margin-bottom: 18px;
    width: 227px;
    margin-left: 0;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
  }
}

.hero__btn {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px 35px;
  margin: 0 auto;
  width: 199px;
  height: 60px;
  background-color: $accent-color;
  color: $black-color;

  border-radius: 4px;
  font-family: $font-family600;
  font-size: 18px;
  line-height: 122%;
  text-align: center;
  letter-spacing: -0.02em;

  transition-property: color;
  transition-duration: 250ms;
  transition-timing-function: $transition-timing-function;

  transition-property: background-color;
  transition-duration: 250ms;
  transition-timing-function: $transition-timing-function;

  @media screen and (min-width: $tablet-width) {
    margin-left: 0;
    width: 227px;
    // height: 56px;
    height: 72px;
    padding: 10px 20px;
    font-size: 24px;
    line-height: 117%;
  }

  &:hover,
  &:focus {
    background-color: $black-color;
    color: $white-color;
  }
}

.hero__swiper {
  margin-bottom: 18px;
  @media screen and (min-width: 751px) {
    margin-bottom: 0;
    width: 40vw;
    margin-left: 0;
    margin-right: 0;
  }
  @media screen and (min-width: 1440px) {
    margin-bottom: 0;
    width: 570px;
    margin-left: 0;
    margin-right: 0;
  }
}

.swiper-wrapper {
  @media screen and (min-width: 1440px) {
    height: 630px;
  }
}

.swiper-wrapper .hero__swiper-slide.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  @media screen and (min-width: 751px) {
    width: 30vw;
  }
  @media screen and (min-width: 1440px) {
    width: 514px;
    height: 507px;
  }
}
.swiper-slide > picture {
  display: inline-block;
  @media screen and (min-width: $tablet-width) {
    width: 486px;
  }
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 261px;
  object-fit: contain;
  @media screen and (min-width: $tablet-width) {
    height: auto;
  }
  @media screen and (min-width: $desktop-width) {
    height: auto;
  }
}

.swiper-wrapper {
  transition-timing-function: linear;
}

.hero__pagination {
  justify-content: center;
  gap: 10px;
  display: flex;
  position: relative;
  margin-top: 18px;
  width: 100%;
  left: 0;
  z-index: 50;
  span {
    width: 14px;
    height: 14px;
    border: 2px solid $background-color;
    border-radius: 50%;
    background-color: $accent-color;
    &:first-of-type {
      background-color: $orange-color;
    }
    &:nth-of-type(3){
      background-color: $black-color;
    }

    &:nth-of-type(4){
      background-color: #19939e;
    }
    &:last-of-type {
      background-color: #bb9d85;
    }
  }
  @media screen and (min-width: $desktop-width) {
    position: absolute;
    margin-top: 0;
    display: block;
    left: 96%;
    span {
      width: 20px;
      height: 20px;
    }
  }
}
.swiper-pagination-bullet {
  opacity: 1;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 2px solid #e8e8e8;
  transform: scale(1.1);
}

.hero__btn[disabled] {
  pointer-events: none;
}
