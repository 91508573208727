.authors {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 72px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (min-width: $desktop-width) {
    gap: 172px;
    :nth-child(2) {
      margin-bottom: 0;
    }
  }
}
.authors__wrap {
  margin: 0;
  max-width: 335px;
  @media screen and (min-width: $tablet-width) {
    max-width: 822px;
  }
}
.authors__text--list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  :nth-child(odd) {
    margin-right: 20px;
  }
  :nth-child(3) {
    margin-top: -40px;
  }
  @media screen and (max-width: 374px) {
    flex-direction: column;
    max-width: 100%;
    gap: 20px;
    :nth-child(odd) {
      margin-right: 0;
    }
    :nth-child(3) {
      margin-top: 0px;
    }
  }
  @media screen and (min-width: $tablet-width) {
    flex-direction: column;
    max-width: 822px;
    gap: 38px;
    :nth-child(odd) {
      margin-right: 0;
    }
    :nth-child(3) {
      margin-top: 0px;
    }
  }
}
.authors__text--item {
  font-family: $font-family400;
  font-size: 14px;
  line-height: 1.43;
  max-width: 157px;
  margin-bottom: 24px;
  padding: 0;
  :nth-child(odd) {
    max-width: 158px;
    margin-right: 0;
  }
  letter-spacing: -0.02em;
  color: $black-color;

  @media screen and (min-width: $tablet-width) {
    font-size: 24px;
    line-height: 1.33;
    max-width: 85vw;
    margin: 0;
  }
  @media screen and (max-width: 374px) {
    flex-direction: column;
    max-width: 100%;
    gap: 20px;
    margin: 0;
  }
}
.authors__text--item--accent--bold {
  font-family: $font-family700;
  margin-right: 0;
}
.authors__text--tem--accent {
  font-family: $font-family700;
  color: $accent-color;
  margin: 0;
}
.author {
  display: flex;
  gap: 8px;
  margin-bottom: 28px;
  justify-content: center;
  @media screen and (min-width: $tablet-width) {
    gap: 75px;
  }
  @media screen and (min-width: $desktop-width) {
    gap: 96px;
    margin-bottom: 76px;
  }
}
.authors__picture {
  max-width: 90px;
  @media screen and (min-width: $tablet-width) {
    max-width: 150px;
  }
  @media screen and (min-width: $desktop-width) {
    max-width: 200px;
  }
}
.authors__info--subtitle {
  font-family: $font-family700;
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: -0.02em;
  margin-bottom: 4px;
  @media screen and (min-width: $desktop-width) {
    font-size: 24px;
    line-height: 1.33;
    margin-bottom: 14px;
  }
}
.authors__info--subtitle--text {
  font-family: $font-family500;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: -0.02em;
  margin-bottom: 4px;
  color: $secondary-text-color;
  @media screen and (min-width: $tablet-width) {
    font-size: 18px;
    line-height: 1.33;
    margin-bottom: 24px;
  }
}
.authors__info--jobTitle--list {
  display: flex;
  flex-direction: column;
  gap: 2px;
  @media screen and (min-width: $tablet-width) {
    gap: 6px;
  }
}
.authors__info--jobTitle--item {
  font-family: $font-family400;
  font-size: 12px;
  line-height: 1.5;
  color: $secondary-text-color;
  @media screen and (min-width: $tablet-width) {
    font-size: 18px;
    line-height: 1.33;
    width: 315px;
  }
}
.authors__speach--wrap {
  position: relative;
  background: #111111;
  border-radius: 4px;
  max-width: 335px;
  padding: 18px 14px;
  @media screen and (min-width: $tablet-width) {
    max-width: 540px;
    padding: 35px;
  }
  @media screen and (min-width: $desktop-width) {
    max-width: 822px;
    padding: 45px;
  }
}
.authors__speach--icon {
  position: absolute;
  left: 30px;
  top: -19px;
  fill: #111111;
  width: 32px;
  height: 22px;
  @media screen and (min-width: $tablet-width) {
    left: 55px;
    top: -25px;
    width: 36px;
    height: 30px;
  }
  @media screen and (min-width: $desktop-width) {
    left: 184px;
    top: -35px;
    width: 46px;
    height: 40px;
  }
}
.authors__speach {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (min-width: $tablet-width) {
    gap: 14px;
  }
}
.authors__speach--text {
  font-family: $font-familyExtraLight400;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.02em;
  color: $white-color;
  @media screen and (min-width: $tablet-width) {
    font-size: 18px;
    line-height: 1.4;
  }
  @media screen and (min-width: $desktop-width) {
    font-size: 24px;
    line-height: 1.33;
  }
}
