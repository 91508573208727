.container.finalSum__container {
  max-width: 375px;
  padding: 0px 20px 36px;
  @media screen and (min-width: $desktop-width) {
    max-width: 1440px;
  }
}

.finalSum__container {
  @media screen and (min-width: $desktop-width) {
    padding: 0;
  }
  &.finalSum__ishidden {
    @include visually-hidden;
  }
}

.finalSum {
  background-color: $background-color;
  max-width: 335px;
  margin-bottom: 72px;
  padding: 18px 12px 24px 12px;
  @media screen and (min-width: $desktop-width) {
    width: 420px;
    max-width: 420px;
    padding: 20px 20px 32px 20px;
  }
}

.finalSum__title {
  font-family: $font-family700;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: calc(18 / 16);
  letter-spacing: -0.01em;
  color: $black-color;
  @media screen and (min-width: $desktop-width) {
    font-size: 24px;
    line-height: calc(28 / 24);
  }
}

.finalSum__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
  @media screen and (min-width: $desktop-width) {
    gap: 14px;
    margin-bottom: 61px;
  }
}

.finalSum__item {
  display: flex;
  justify-content: space-between;
}
.finalSum__descr {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: $secondary-text-color;
  @media screen and (min-width: $desktop-width) {
    font-size: 16px;
    line-height: calc(18 / 16);
  }
}
.finalSum__amount {
  font-family: $font-family500;
  font-size: 12px;
  line-height: calc(18 / 12);
  letter-spacing: -0.01em;
  color: $black-color;
  @media screen and (min-width: $desktop-width) {
    font-size: 16px;
    line-height: calc(18 / 16);
    &--font {
      font-size: 14px;
      line-height: calc(18 / 14);
    }
  }
}
.finalSum__total {
  margin-bottom: 39px;
  position: relative;
  display: flex;
  justify-content: space-between;
  &::after {
    content: '';
    position: absolute;
    top: 16px;
    display: block;
    margin-top: 14px;
    width: 100%;
    max-width: 311px;
    height: 1px;

    background: $black-color;
    opacity: 0.5;
  }
  &::before {
    content: '';
    position: absolute;
    top: -30px;
    display: block;
    margin-top: 14px;
    width: 100%;
    max-width: 311px;
    height: 1px;

    background: $black-color;
    opacity: 0.5;
  }
  @media screen and (min-width: $desktop-width) {
    margin-bottom: 47px;
    align-items: center;
    &::before {
      top: -26px;
      max-width: 380px;
    }
    &::after {
      top: 20px;
      max-width: 380px;
    }
  }
}
.finalSum__totalDescr {
  font-size: 14px;
  line-height: calc(18 / 14);
  letter-spacing: -0.01em;
  color: $black-color;
  @media screen and (min-width: $desktop-width) {
    font-size: 16px;
    line-height: calc(18 / 16);
  }
}
.finalSum__totalAmount {
  font-family: $font-family500;
  font-size: 16px;
  line-height: calc(18 / 16);
  letter-spacing: -0.01em;
  color: $black-color;
  @media screen and (min-width: $desktop-width) {
    font-size: 24px;
    line-height: calc(32 / 24);
  }
}
.finalSum__btn {
  font-family: $font-family600;
  padding: 12px 0;
  width: 100%;
  max-width: 311px;
  font-size: 16px;
  line-height: calc(18 / 16);
  letter-spacing: -0.02em;
  @media screen and (min-width: $desktop-width) {
    padding: 14px 0;
    width: 100%;
    max-width: 380px;
    font-size: 24px;
    line-height: calc(28 / 24);
  }
  &:disabled {
    background: grey;
  }
}
