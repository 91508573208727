@keyframes my-bounce {
  from,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -20%, 0) scaleY(1.1);
  }

  70% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -10%, 0) scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, -4%, 0) scaleY(1.02);
  }

  100% {
    opacity: 1;
  }
}

@keyframes sploosh {
  0% {
    box-shadow: 0 0 0 0px rgba(86, 86, 86, 0.7);
    background: rgba(49, 49, 49, 0.7);
  }
  80% {
    background: rgba(66, 166, 223, 0);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(66, 166, 223, 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  3.3% {
    transform: scale(1.1);
  }
  16.5% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoom-in-animate {
  0% {
    transform: scale(0.2, 0.2);
  }
  30% {
    transform: scale(1.2, 1.2);
  }
  90% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.desc-container {
  margin: 0 auto;
  padding: 0 20px;
  width: 90vw;
  max-width: 1920px;
}

.description {
  padding-bottom: 134px;

  @media screen and (min-width: $tablet-width) {
    padding-bottom: 172px;
  }
}

.description__title {
  margin: 0 auto;
  margin-bottom: 80px;
  letter-spacing: -0.02em;

  @media screen and (min-width: $tablet-width) {
    max-width: 670px;
    margin-bottom: 20px;
  }
  @media screen and (min-width: $desktop-width) {
    max-width: 980px;
  }
}

.description__content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30vw 0;

  @media screen and (min-width: $tablet-width) {
    margin: 0;
  }
}

.img-wrapper {
  position: relative;
  width: 90vw;
  max-width: 960px;

  @media screen and (min-width: $tablet-width) {
    width: 50vw;
  }
}

.description__image {
  width: 100%;
}

.description__button-wrapper {
  position: absolute;

  width: 14px;
  height: 14px;

  @media screen and (min-width: $tablet-width) {
    width: 22px;
    height: 22px;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;

    width: 100%;
    height: 100%;
    border-radius: 50%;

    animation: sploosh 2s cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-iteration-count: infinite;
  }

  span:nth-child(2) {
    animation-delay: 0.33s;
    animation-duration: 2.2s;
  }

  &:nth-child(1) {
    top: 18%;
    right: 28%;
  }

  &:nth-child(2) {
    top: 36%;
    right: 40%;
  }

  &:nth-child(3) {
    bottom: 42%;
    right: 28%;
  }

  &:nth-child(4) {
    bottom: 18%;
    right: 30%;
  }

  &:nth-child(5) {
    bottom: 16%;
    left: 26%;
  }

  &:nth-child(6) {
    bottom: 44%;
    left: 26%;
  }

  &:nth-child(7) {
    top: 30%;
    left: 30%;
  }

  &:nth-child(8) {
    top: 8%;
    left: 46%;
  }
}

.description__button {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: none;
  background-color: $black-color;
  cursor: pointer;
  animation: pulse 2s ease-out;
  animation-iteration-count: infinite;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.description__item {
  position: absolute;
  opacity: 0;

  transition: opacity $transition-duration $transition-timing-function;

  &.active {
    opacity: 1;

    @media screen and (min-width: $tablet-width) {
      animation-name: zoom-in-animate;
      animation-duration: 1600ms;
      animation-timing-function: $transition-timing-function;
      animation-fill-mode: forwards;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 14vw;
    height: 14vw;
  }

  &:nth-child(1) {
    top: -20%;
    right: 30%;

    &::before {
      background: url('./../images/description/mob_1x_round_5.png') center /
        100% 100% no-repeat;

      @media (min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background: url('./../images/description/mob_2x_round_5.png') center /
          100% 100% no-repeat;
      }
    }
  }

  &:nth-child(2) {
    top: 10%;
    right: 0;

    &::before {
      background: url('./../images/description/mob_1x_round_6.png') center /
        100% 100% no-repeat;

      @media (min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background: url('./../images/description/mob_2x_round_6.png') center /
          100% 100% no-repeat;
      }
    }
  }

  &:nth-child(3) {
    bottom: 30%;
    right: 0;

    &::before {
      background: url('./../images/description/mob_1x_round_2.png') center /
        100% 100% no-repeat;

      @media (min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background: url('./../images/description/mob_2x_round_2.png') center /
          100% 100% no-repeat;
      }
    }
  }

  &:nth-child(4) {
    bottom: -10%;
    right: 10%;

    &::before {
      background: url('./../images/description/mob_1x_round_1.png') center /
        100% 100% no-repeat;

      @media (min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background: url('./../images/description/mob_2x_round_1.png') center /
          100% 100% no-repeat;
      }
    }
  }

  &:nth-child(5) {
    bottom: -20%;
    left: 30%;

    &::before {
      background: url('./../images/description/mob_1x_round_4.png') center /
        100% 100% no-repeat;

      @media (min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background: url('./../images/description/mob_2x_round_4.png') center /
          100% 100% no-repeat;
      }
    }
  }

  &:nth-child(6) {
    bottom: 10%;
    left: 0;

    &::before {
      background: url('./../images/description/mob_1x_round_3.png') center /
        100% 100% no-repeat;

      @media (min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background: url('./../images/description/mob_2x_round_3.png') center /
          100% 100% no-repeat;
      }
    }
  }

  &:nth-child(7) {
    top: 30%;
    left: 0;

    &::before {
      background: url('./../images/description/mob_1x_round_7.png') center /
        100% 100% no-repeat;

      @media (min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background: url('./../images/description/mob_2x_round_7.png') center /
          100% 100% no-repeat;
      }
    }
  }

  &:nth-child(8) {
    top: -10%;
    left: 10%;

    &::before {
      background: url('./../images/description/desk_1x_round_8.png') center /
        100% 100% no-repeat;

      @media (min-device-pixel-ratio: 2),
        (min-resolution: 192dpi),
        (min-resolution: 2dppx) {
        background: url('./../images/description/desk_2x_round_8.png') center /
          100% 100% no-repeat;
      }
    }
  }

  @media screen and (min-width: $tablet-width) {
    &::before {
      width: 8vw;
      height: 8vw;
      max-width: 154px;
      max-height: 154px;
    }

    &:nth-child(1) {
      top: 10%;
      right: 6%;

      &::before {
        background: url('./../images/description/desk_1x_round_5.png') center /
          100% 100% no-repeat;

        @media (min-device-pixel-ratio: 2),
          (min-resolution: 192dpi),
          (min-resolution: 2dppx) {
          background: url('./../images/description/desk_2x_round_5.png') center /
            100% 100% no-repeat;
        }
      }
    }

    &:nth-child(2) {
      top: 30%;
      right: 20%;

      &::before {
        background: url('./../images/description/desk_1x_round_6.png') center /
          100% 100% no-repeat;

        @media (min-device-pixel-ratio: 2),
          (min-resolution: 192dpi),
          (min-resolution: 2dppx) {
          background: url('./../images/description/desk_2x_round_6.png') center /
            100% 100% no-repeat;
        }
      }
    }

    &:nth-child(3) {
      bottom: 40%;
      right: 6%;

      &::before {
        background: url('./../images/description/desk_1x_round_2.png') center /
          100% 100% no-repeat;

        @media (min-device-pixel-ratio: 2),
          (min-resolution: 192dpi),
          (min-resolution: 2dppx) {
          background: url('./../images/description/desk_2x_round_2.png') center /
            100% 100% no-repeat;
        }
      }
    }

    &:nth-child(4) {
      bottom: 20%;
      right: 20%;

      &::before {
        background: url('./../images/description/desk_1x_round_1.png') center /
          100% 100% no-repeat;

        @media (min-device-pixel-ratio: 2),
          (min-resolution: 192dpi),
          (min-resolution: 2dppx) {
          background: url('./../images/description/desk_2x_round_1.png') center /
            100% 100% no-repeat;
        }
      }
    }

    &:nth-child(5) {
      bottom: 20%;
      left: 20%;

      &::before {
        background: url('./../images/description/desk_1x_round_4.png') center /
          100% 100% no-repeat;

        @media (min-device-pixel-ratio: 2),
          (min-resolution: 192dpi),
          (min-resolution: 2dppx) {
          background: url('./../images/description/desk_2x_round_4.png') center /
            100% 100% no-repeat;
        }
      }
    }

    &:nth-child(6) {
      bottom: 40%;
      left: 6%;

      &::before {
        background: url('./../images/description/desk_1x_round_3.png') center /
          100% 100% no-repeat;

        @media (min-device-pixel-ratio: 2),
          (min-resolution: 192dpi),
          (min-resolution: 2dppx) {
          background: url('./../images/description/desk_2x_round_3.png') center /
            100% 100% no-repeat;
        }
      }
    }

    &:nth-child(7) {
      top: 30%;
      left: 20%;

      &::before {
        background: url('./../images/description/desk_1x_round_7.png') center /
          100% 100% no-repeat;

        @media (min-device-pixel-ratio: 2),
          (min-resolution: 192dpi),
          (min-resolution: 2dppx) {
          background: url('./../images/description/desk_2x_round_7.png') center /
            100% 100% no-repeat;
        }
      }
    }

    &:nth-child(8) {
      top: 10%;
      left: 6%;

      &::before {
        background: url('./../images/description/desk_1x_round_8.png') center /
          100% 100% no-repeat;

        @media (min-device-pixel-ratio: 2),
          (min-resolution: 192dpi),
          (min-resolution: 2dppx) {
          background: url('./../images/description/desk_2x_round_8.png') center /
            100% 100% no-repeat;
        }
      }
    }
  }
}

.description__text {
  position: absolute;
  padding-top: 10px;
  width: 140%;
  font-size: 2vw;
  font-family: $font-family700;
  line-height: calc(10 / 8);
  text-transform: uppercase;
  opacity: 0;

  @media screen and (min-width: $tablet-width) {
    font-size: 1.2vw;
    line-height: calc(28 / 24);
  }

  @media screen and (min-width: 1921px) {
    font-size: 24px;
  }

  &.active {
    animation-name: my-bounce;
    animation-delay: 600ms;
    animation-duration: 600ms;
    animation-fill-mode: forwards;
  }
}

.benefits {
  margin: 0 auto;
  width: 100%;
  // max-width: 824px;
  padding: 14px 14px 14px 24px;
  font-size: 14px;
  line-height: calc(18 / 14);
  background-color: $background-color;
  border-radius: 4px;

  @media screen and (min-width: $tablet-width) {
    padding: 48px 98px 48px 116px;
    font-size: 24px;
    line-height: calc(32 / 24);
  }
  @media screen and (min-width: $desktop-width) {
    max-width: 824px;
  }
}

.benefits-list__item {
  padding-left: 12px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 2px;
    height: 2px;
    background-color: $black-color;
    border-radius: 50%;
    top: 8px;
    left: 0;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media screen and (min-width: $tablet-width) {
    padding-left: 20px;

    &::before {
      width: 3px;
      height: 3px;
      top: 12px;
    }

    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }
}

.description__hint {
  position: absolute;
  bottom: 2%;
  right: 30%;
  width: 16%;
  opacity: 1;
  transition: opacity $transition-duration $transition-timing-function;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.hint {
  position: absolute;
  font-size: 3vw;
  bottom: -6%;
  left: -90%;
  color: #555;

  @media screen and (min-width: $tablet-width) {
    font-size: 2vw;
    bottom: -10%;
    left: -100%;
  }

  @media screen and (min-width: 1401px) {
    font-size: 26px;
    bottom: -6%;
    left: -80%;
  }
}

.hint-img {
  max-width: 100%;
}
