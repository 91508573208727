.title {
  font-size: 28px;
  font-family: $font-family700;
  line-height: calc(32 / 28);
  text-align: center;
  margin-bottom: 40px;
  @media screen and (min-width: $tablet-width) {
    font-size: 44px;
    line-height: 1.22;
    margin-bottom: 42px;
  }
  @media screen and (min-width: $desktop-width) {
    font-size: 64px;
    line-height: calc(68 / 64);
    margin-bottom: 72px;
  }
}
