$desktop-width: 1440px;
$phone-width: 375px;
$tablet-width: 751px;
$desktop-max-width: 1920px;

$accent-color: #f3bb4a;
$black-color: #111111;
$white-color: #ffffff;
$orange-color: #c34627;
$background-color: #f3f3f3;
$alert-color: #f7434b;
$success-color: #01a045;
$secondary-text-color: #3c3b3b;

$font-family800: 'MacPawFixelDisplay800', sans-serif;
$font-family700: 'MacPawFixelDisplay700', sans-serif;
$font-family600: 'MacPawFixelDisplay600', sans-serif;
$font-family500: 'MacPawFixelDisplay500', sans-serif;
$font-family400: 'MacPawFixelDisplay400', sans-serif;
$font-familyLight400: 'MacPawFixelDisplayLight400', sans-serif;
$font-familyExtraLight400: 'MacPawFixelDisplayExtraLight400', sans-serif;

$regular-text: 400;
$medium-text: 500;
$bold-text: 700;

$transition-duration: 250ms;
$transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
